                                                           
import $ from 'jquery';
window.jQuery = $;
window.$ = $;
//import './css/reset.css';
import './app.scss'; 
import './css/colorbox.css'; 

import '../elements/css/fonts.css';

import '@fortawesome/fontawesome-free/js/fontawesome';
import '@fortawesome/fontawesome-free/js/solid';
import '@fortawesome/fontawesome-free/js/regular';
import '@fortawesome/fontawesome-free/js/brands';


import '@fontsource/homemade-apple'; 

import 'jquery-colorbox';
import { common } from './js/common.js';
common();
import objectFitImages from 'object-fit-images';
objectFitImages('.obj-fit img');
// import 'jquery-parallax.js';
import 'slick-carousel';